import {
  TideItemForCountry,
  TideItemForDestinations,
  TideItemForNavigationSection,
  TideItemForNewsletterForm,
  TideItemForNotificationComponentConnection,
  TideItemForRegion,
  TideItemForSearchPage,
  TideItemForUspGroup,
  TideItemForWebsite,
} from "../types";
import { compact, first, isEmpty, isNil } from "lodash";

import Breadcrumb from "../components/breadcrumb";
import DestinationIntro from "../components/destination-intro";
import Footer from "../components/footer";
import Hero from "../components/hero";
import Layout from "../components/layout";
import { LookupQueryData } from "../components/qsm";
import Navbar from "../components/navbar";
import PageComponents from "../components/page-components";
import React from "react";
import { generatePath, getChildNavigationLinks } from "../utils";
import { graphql } from "gatsby";
import StickyBar from "../components/sticky-bar";
import Link from "../components/link";
import Icon from "../components/icon";
import Seo from "../components/seo";
import NotificationBar from "../components/notification-bar";
import { AlternateRoute } from "../../.gatsby/gatsby-node";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";

interface RegionPageTemplateProps {
  data: RegionPageData;
  pageContext: {
    alternateRoutes: AlternateRoute[];
  };
}

const RegionPageTemplate: React.FC<RegionPageTemplateProps> = ({ data, pageContext }) => {
  const notificationBar = first(data.notifications?.nodes);
  const generalCountryOrRegionContent = data.countryOrRegion?.content?.general;
  const { t } = useI18next();

  const getSearchUri = () => {
    const searchPath = data.searchPage?.content?.general?.slug ?? "zoeken";
    if (data.countryOrRegion?.templateName === "Country") {
      const item = data.countryOrRegion as TideItemForCountry;
      return `/${searchPath}?country=${item?.content?.general?.country?.tideId}`;
    } else {
      const item = data.countryOrRegion as TideItemForRegion;
      return `/${searchPath}?region=${item?.content?.general?.region?.tideId}`;
    }
  };

  const parentCountry =
    data.countryOrRegion?.parentItem?.templateName === "Country"
      ? (data.countryOrRegion?.parentItem as TideItemForCountry)
      : undefined;
  const destionationsItem = parentCountry
    ? (parentCountry.parentItem?.parentItem as TideItemForDestinations)
    : (data.countryOrRegion?.parentItem?.parentItem as TideItemForDestinations);

  return (
    <Layout>
      <Seo
        title={generalCountryOrRegionContent?.title ?? ""}
        seoTitle={data.countryOrRegion?.content?.seo?.seoTitle ?? undefined}
        seoDescription={data.countryOrRegion?.content?.seo?.seoDescription ?? undefined}
        seoKeywords={data.countryOrRegion?.content?.seo?.seoKeywords ?? undefined}
      />
      {!isEmpty(notificationBar?.content?.general?.notification) && (
        <NotificationBar
          text={notificationBar?.content?.general?.notification ?? ""}
          icon={notificationBar?.content?.general?.iconFontAwesome ?? "fas fa-info"}
          backgroundColor={notificationBar?.content?.general?.backgroundColor ?? "#e74c3c"}
        />
      )}
      <Navbar
        alternateRoutes={pageContext.alternateRoutes}
        mainNavigationSection={data.mainNavigationSection}
        fullscreenPrimaryNavigationSection={data.fullscreenPrimaryNavigationSection}
        fullscreenSecondaryNavigationSection={data.fullscreenSecondaryNavigationSection}
        socialNavigationSection={data.socialNavigationSection}
        legalNavigationSection={data.legalNavigationSection}
        phone={data.website?.content?.contact?.phone ?? ""}
        destinations={data.navigationDestinations}
      />
      <StickyBar
        navigationLinks={getChildNavigationLinks(data.stickyNavigationSection)}
        actionsComponent={
          <Link
            href={getSearchUri()}
            internal
            title={t("SEARCH_AND_BOOK")}
            className="cta cta--icon"
          >
            <Icon name="ui-search" />
            <Trans>SEARCH_AND_BOOK</Trans>
          </Link>
        }
      />
      <Hero
        type="product"
        title={data.countryOrRegion?.content?.general?.title ?? undefined}
        imageSrc={data.countryOrRegion?.content?.general?.headerImage?.url ?? undefined}
      />
      <Breadcrumb
        items={compact([
          { id: "home", title: "Home", url: "/" },
          {
            id: "destinations",
            title: destionationsItem?.content?.general?.title ?? "",
            url: generatePath(destionationsItem),
          },
          parentCountry && {
            id: "country",
            title:
              (!isEmpty(parentCountry?.content?.general?.title)
                ? parentCountry?.content?.general?.title
                : parentCountry?.name) ?? "",
            url: generatePath(parentCountry, generatePath(destionationsItem)),
          },
          !isNil(data.countryOrRegion) && {
            id: "countryOrRegion",
            title:
              (!isEmpty(generalCountryOrRegionContent?.title)
                ? generalCountryOrRegionContent?.title
                : data.countryOrRegion.name) ?? "",
            url: generatePath(data.countryOrRegion),
          },
        ])}
      />
      <DestinationIntro
        title={data.countryOrRegion?.content?.description?.subtitle ?? undefined}
        intro={data.countryOrRegion?.content?.description?.intro ?? undefined}
        climate={data.countryOrRegion?.content?.description?.climate ?? undefined}
        currency={data.countryOrRegion?.content?.description?.currency ?? undefined}
        timeDifference={data.countryOrRegion?.content?.description?.timeDifference ?? undefined}
        travelFormalities={
          data.countryOrRegion?.content?.description?.travelFormalities ?? undefined
        }
        language={data.countryOrRegion?.content?.description?.language ?? undefined}
      />
      <PageComponents componentItems={compact(data.countryOrRegion?.childItems)} />
      <Footer
        footerNavigationSection={data.footerNavigationSection}
        socialNavigationSection={data.socialNavigationSection}
        legalNavigationSection={data.legalNavigationSection}
        address={data.website?.content?.contact?.address ?? ""}
        phone={data.website?.content?.contact?.phone ?? ""}
        email={data.website?.content?.contact?.email ?? ""}
        uspGroup={data.footerUspGroup}
        newsletterForm={data.newsletterForm}
      />
    </Layout>
  );
};

interface RegionPageData extends LookupQueryData {
  searchPage: TideItemForSearchPage;
  countryOrRegion?: TideItemForCountry | TideItemForRegion;
  website: TideItemForWebsite;
  notifications: TideItemForNotificationComponentConnection;
  navigationDestinations: TideItemForDestinations;
  mainNavigationSection: TideItemForNavigationSection;
  fullscreenPrimaryNavigationSection: TideItemForNavigationSection;
  fullscreenSecondaryNavigationSection: TideItemForNavigationSection;
  legalNavigationSection: TideItemForNavigationSection;
  stickyNavigationSection: TideItemForNavigationSection;
  socialNavigationSection: TideItemForNavigationSection;
  contactNavigationSection: TideItemForNavigationSection;
  footerNavigationSection: TideItemForNavigationSection;
  footerUspGroup: TideItemForUspGroup;
  newsletterForm: TideItemForNewsletterForm;
}

export const query = graphql`
  query RegionPageQuery($id: String, $language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    searchPage: tideItemForSearchPage(language: { eq: $language }) {
      id
      content {
        general {
          slug
        }
      }
    }
    countryOrRegion: tideItem(id: { eq: $id }) {
      id
      name
      templateName
      ... on TideItemForCountry {
        content {
          general {
            title
            slug
            headerImage {
              url
            }
            country {
              tideId
            }
          }
          seo {
            seoTitle
            seoDescription
            seoKeywords
          }
          description {
            subtitle
            intro
            currency
            travelFormalities
            timeDifference
            climate
            language
          }
        }
      }
      ... on TideItemForRegion {
        content {
          general {
            title
            slug
            headerImage {
              url
            }
            region {
              tideId
            }
          }
          seo {
            seoTitle
            seoDescription
            seoKeywords
          }
          description {
            subtitle
            intro
            currency
            travelFormalities
            timeDifference
            climate
            language
          }
        }
      }
      ...pageComponentsFragment
      ...countryOrRegionPathFragment
    }
    website: tideItemForWebsite(name: { eq: "Travelworld" }, language: { eq: $language }) {
      content {
        contact {
          phone
          email
          address
        }
      }
    }
    ...navigationDestinationsFragment
    notifications: allTideItemForNotificationComponent(filter: { language: { eq: $language } }) {
      nodes {
        content {
          general {
            notification
            iconFontAwesome
            backgroundColor
          }
        }
      }
    }
    mainNavigationSection: tideItemForNavigationSection(
      name: { eq: "Main Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    fullscreenPrimaryNavigationSection: tideItemForNavigationSection(
      name: { eq: "Fullscreen Primary" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    fullscreenSecondaryNavigationSection: tideItemForNavigationSection(
      name: { eq: "Fullscreen Secondary" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    socialNavigationSection: tideItemForNavigationSection(
      name: { eq: "Social Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    contactNavigationSection: tideItemForNavigationSection(
      name: { eq: "Contact Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    legalNavigationSection: tideItemForNavigationSection(
      name: { eq: "Legal Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    stickyNavigationSection: tideItemForNavigationSection(
      name: { eq: "Sticky Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    footerNavigationSection: tideItemForNavigationSection(
      name: { eq: "Footer Navigation" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    footerUspGroup: tideItemForUspGroup(name: { eq: "Footer Usps" }, language: { eq: $language }) {
      ...uspGroupFields
    }
    newsletterForm: tideItemForNewsletterForm(
      name: { eq: "Newsletter" }
      language: { eq: $language }
    ) {
      content {
        externalMail {
          templateConfirmationMail {
            tideId
          }
        }
        contactDetails {
          tag {
            tideId
          }
        }
        form {
          privacyPage
        }
      }
    }
  }
`;

export default RegionPageTemplate;
