import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";

interface DestinationIntroProps {
  title?: string;
  intro?: string;
  currency?: string;
  travelFormalities?: string;
  timeDifference?: string;
  climate?: string;
  language?: string;
}

const DestinationIntro: React.FC<DestinationIntroProps> = ({
  title,
  intro,
  currency,
  travelFormalities,
  timeDifference,
  climate,
  language,
}) => {
  const { t } = useI18next();
  return (
    <section className="section">
      <div className="destination-intro">
        <div className="destination-intro__content">
          <div className="destination-intro__copy">
            {title && <h2>{title}</h2>}
            {intro && <div dangerouslySetInnerHTML={{ __html: intro }} />}
          </div>
          <div className="destination-intro__summary">
            <div className="accent-frame">
              <dl>
                {currency && (
                  <>
                    <dt>{t("VALUTA")}:</dt>
                    <dd dangerouslySetInnerHTML={{ __html: currency }} />
                  </>
                )}
                {travelFormalities && (
                  <>
                    <dt>{t("TRAVEL_FORMALITIES")}:</dt>
                    <dd dangerouslySetInnerHTML={{ __html: travelFormalities }} />
                  </>
                )}
                {timeDifference && (
                  <>
                    <dt>{t("TIME_DIFFERENCE")}:</dt>
                    <dd dangerouslySetInnerHTML={{ __html: timeDifference }} />
                  </>
                )}
                {climate && (
                  <>
                    <dt>{t("CLIMATE")}:</dt>
                    <dd dangerouslySetInnerHTML={{ __html: climate }} />
                  </>
                )}
                {language && (
                  <>
                    <dt>{t("LANGUAGE")}:</dt>
                    <dd dangerouslySetInnerHTML={{ __html: language }} />
                  </>
                )}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DestinationIntro;
